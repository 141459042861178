<template>
    <div>
        <b-card>
            <b-card-title>
                <b-row >
                    <b-col xl="10" md="9" lg="10" class="mt-1">
                        <h3 >
                            Eventos del Proyecto #{{this.project.projectCode}}
                        </h3>
                    </b-col>
                    <b-col xl="2" md="3" lg="2" style="margin-top:5px;text-align:right">                         
                        <b-button variant="primary" block @click="createEvent">                        
                            <feather-icon icon="PlusIcon" class="mr-50"/>
                            <span class="align-middle">Crear evento</span>
                        </b-button>
                    </b-col>  
                </b-row> 

            </b-card-title>

            <b-table                 
                ref="selectableTable"
                :fields="fields" 
                :items="projectEventsDetails"                
                responsive
                selectable
                class="mb-0"
                :select-mode="selectMode"
                @row-selected="onRowSelected">
                
                <template #cell(eventDate)="data">
                    {{((data.item.eventDate).substring(0,10))}}                    
                </template>                

            </b-table>

            <b-row v-if="this.projectEventsDetails.length <= 0"  class="justify-content-center mt-3" style="text-align:center">
                <b-col style="justify-content:center" xl="4" md="6" lg="4"  >
                    <b-alert variant="primary" show>
                        <div class="alert-body">
                            <span><strong>No Hay Eventos para mostrar</strong></span>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>

        </b-card>

        <!-- Modal -->

        <b-modal size="sm" ref="create-new-event" id="create-new-event" centered hide-footer title="Digita la siguiente información">            
            <b-card-text>                 
                <b-form>
                    <b-row>
                        <b-col md="12" xl="12" class="mb-0">
                            <b-form-group label="Tipo de Evento" label-for="basicInput">                        
                                <b-form-select @change="eventTypeSelected" v-model="eventType" :options="eventTypeOptions" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <!-- <b-col md="12" xl="12" class="mb-0">
                            <b-form-group label="Descripción" label-for="basicInput">
                                <b-form-input v-model="description" placeholder="Digíte"/>
                            </b-form-group>
                        </b-col> -->

                        <b-col md="12" xl="12" class="mb-0">
                            <label for="example-datepicker">Description</label>
                            <b-form-textarea v-model="description" id="textarea-default" rows="5"/>
                        </b-col>   
                    </b-row>
                    <b-row>
                        <b-col md="12" xl="12" class="mb-0">
                            <label for="example-datepicker">Fecha Evento</label>
                            <b-form-datepicker v-model="eventDate" id="example-datepicker1" class="mb-1"/>
                        </b-col>
                    </b-row>

                    <b-row class="mt-1">
                        <b-col>
                            <h6>
                                Link
                            </h6>
                        </b-col>                        
                    </b-row>
                    <b-row>
                        <b-col md="12" xl="12" class="mb-1">                            
                            <b-form-input v-model="link" placeholder="Copia y pega el link de youtube"/>                            
                        </b-col>
                    </b-row>

                    <b-row class="mt-2">
                        <b-col>
                            <h6>
                                Carga de Videos
                            </h6>
                        </b-col>                        
                    </b-row>

                    <b-row>
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Video 1" label-for="basicInput">
                                <b-form-file placeholder="Seleccione" v-model="videoFile1" />
                                <b-card-text @click="openVideo(eventSelected.eventVideoUrl1)" v-if="eventSelected.eventVideoUrl1" class="cursor-pointer sosty-font-smaller" style="color:#00BD56">Ver<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Video 2" label-for="basicInput">
                                <b-form-file placeholder="Seleccione" v-model="videoFile2" />
                                <b-card-text @click="openVideo(eventSelected.eventVideoUrl2)" v-if="eventSelected.eventVideoUrl2" class="cursor-pointer sosty-font-smaller" style="color:#00BD56">Ver<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Video 3" label-for="basicInput">
                                <b-form-file placeholder="Seleccione" v-model="videoFile3" />
                                <b-card-text @click="openVideo(eventSelected.eventVideoUrl3)" v-if="eventSelected.eventVideoUrl3" class="cursor-pointer sosty-font-smaller" style="color:#00BD56">Ver<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    
                    <b-row class="mt-2">
                        <b-col>
                            <h6>
                                Carga de Imágenes
                            </h6>
                        </b-col>                        
                    </b-row>

                    <b-row>
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Imagen 1" label-for="basicInput">
                                <b-form-file placeholder="Seleccione" v-model="picture1File" accept=".jpg,.png"/>
                                <b-card-text @click="openDocument(eventSelected.picture1Url)" v-if="eventSelected.picture1Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>

                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Imagen 2" label-for="basicInput">
                                <b-form-file placeholder="Seleccione" v-model="picture2File" accept=".jpg,.png" />
                                <b-card-text @click="openDocument(eventSelected.picture2Url)" v-if="eventSelected.picture2Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Imagen 3" label-for="basicInput">
                                <b-form-file placeholder="Seleccione una imagen" v-model="picture3File" accept=".jpg,.png" />
                                <b-card-text @click="openDocument(eventSelected.picture3Url)" v-if="eventSelected.picture3Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>

                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Imagen 4" label-for="basicInput">
                                <b-form-file placeholder="Seleccione una imagen" v-model="picture4File" accept=".jpg,.png"/>
                                <b-card-text @click="openDocument(eventSelected.picture4Url)" v-if="eventSelected.picture4Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Imagen 5" label-for="basicInput">
                                <b-form-file placeholder="Seleccione una imagen" v-model="picture5File" accept=".jpg,.png" />
                                <b-card-text @click="openDocument(eventSelected.picture5Url)" v-if="eventSelected.picture5Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="mt-2">
                        <b-col>
                            <h6>
                                Carga de Archivos
                            </h6>
                        </b-col>                        
                    </b-row>

                    <b-row>
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Archivo 1" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un archivo" v-model="file1File" accept=".pdf"/>
                                <b-card-text @click="openDocument(eventSelected.file1Url)" v-if="eventSelected.file1Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>

                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Archivo 2" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un archivo" v-model="file2File" accept=".pdf"/>
                                <b-card-text @click="openDocument(eventSelected.file2Url)" v-if="eventSelected.file2Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Archivo 3" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un archivo" v-model="file3File" accept=".pdf"/>
                                <b-card-text @click="openDocument(eventSelected.file3Url)" v-if="eventSelected.file3Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="12">
                            <b-form-checkbox v-model="this.sendNotificationCheck" @change="changeSendNotificationCheck()" checked="false" name="check-button" switch inline> 
                                Notificar Neoganaderos
                            </b-form-checkbox>
                        </b-col>
                    </b-row>

                    <b-row  class="mt-1 justify-content-end">
                        <b-col v-if="!processing" class="mt-2" md="4" lg="4">
                            <b-button variant="primary" @click="SaveProjectEvent" block>                                                            
                                Guardar
                            </b-button>
                        </b-col>
                        <b-col v-if="processing" class="mt-2" md="4" lg="4">
                            <b-button variant="primary" disabled block>
                                <b-spinner small label="Cargando..." />
                            </b-button>
                        </b-col>
                    </b-row>                    
                </b-form>                
            </b-card-text>        
        </b-modal>

        <b-modal size="sm" ref="edit-delete-event" centered  id="edit-delete-event" hide-footer title="Que deseas hacer?">                                                        
            <b-col xl="12" md="12" lg="12">            
                <b-row  style="text-align:center">                
                    <b-button class="mt-1" variant="primary" block @click="editEvent">
                        <feather-icon icon="EditIcon" class="mr-50"/>
                        <span class="align-middle">Editar Evento</span>                                    
                    </b-button>                
                </b-row>
                <b-row style="text-align:center">
                    <b-button class="mt-1" variant="warning" block @click="deleteEvent">
                        <feather-icon icon="DeleteIcon" class="mr-50"/>
                        <span class="align-middle">Borrar Evento</span>                                    
                    </b-button>                
                </b-row>
            </b-col>
        </b-modal> 
        
    </div>
    
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol,  BLink, BTable, BCalendar, BFormDatepicker, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner,  
  BCard, BModal, BAlert, BFormSelect, BFormFile, BFormTextarea
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import axios from '@axios'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
export default {

components: {
    BRow,
    BAlert,
    BCardCode,
    BCalendar,
    BCol,
    BTable,
    BLink,
    BCard,
    BFormDatepicker,
    BModal,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,     
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,    
    BSpinner,
    Cleave,
    Ripple,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    },
    data(){
        return{
            processing: false,
            projectID: null,
            projectEventsDetails:{},
            project:{},
            projectEventsDetails:[],
            fields:[
                {key: 'eventDate', label: 'Fecha Evento'},
                {key: 'description', label: 'Descripción'},
                {key: 'eventType', label: 'Tipo Evento'},
            ],
            eventSelected: {},
            eventTypeOptions:[                
                {value: 'Compra de Animales', text: 'Compra de Animales'},
                {value: 'Transporte de Animales', text: 'Transporte de Animales'},
                {value: 'Actualizacion General', text: 'Actualizacion General'},
                {value: 'Muerte Animal', text: 'Muerte Animal'},
                {value: 'Carga de Documentos', text: 'Carga de Documentos'},
                {value: 'Nacimiento de Crias', text: 'Nacimiento de Crias'}
            ],            
            selectMode: 'single',
            eventType:'',
            description: null,
            eventDate: null,
            picture1Url: null,
            picture2Url: null,
            picture3Url: null,
            picture4Url: null,
            picture5Url: null, 
            file1Url: null,
            file2Url: null,
            file3Url: null,
            payload:{},
            projectEvent:{},
            projectEventID: null,
            picture1File: null,
            picture2File: null,
            picture3File: null,
            picture4File: null,
            picture5File: null,
            file1File: null,
            file2File: null,
            file3File: null,
            sendNotificationCheck: false,
            eventVideoUrl1: null,
            eventVideoUrl2: null,
            eventVideoUrl3: null,
            link: null,
            videoFile1: null,
            videoFile2: null,
            videoFile3: null,
            videoUrl: null,
            url: null,
        }
    },

    created(){
        if(this.$route != null && this.$route.query != null && this.$route.query.projectID != null) {
            this.projectID = this.$route.query.projectID;   
    
        this.GetProjectEvents();
        this.GetProjectPublicById();    
        } 
    },
    watch:{
        projectEventID: function(val) { this.projectEventID = val; },
        picture1File: function(val) { this.picture1File = val; this.uploadFile(val, 'picture1File'); },
        picture2File: function(val) { this.picture2File = val; this.uploadFile(val, 'picture2File'); },
        picture3File: function(val) { this.picture3File = val; this.uploadFile(val, 'picture3File'); },
        picture4File: function(val) { this.picture4File = val; this.uploadFile(val, 'picture4File'); },
        picture5File: function(val) { this.picture5File = val; this.uploadFile(val, 'picture5File'); },
        file1File: function(val) { this.file1File = val; this.uploadFile(val, 'file1File'); },
        file2File: function(val) { this.file2File = val; this.uploadFile(val, 'file2File'); },
        file3File: function(val) { this.file3File = val; this.uploadFile(val, 'file3File'); },
        videoFile1: function(val) {this.videoFile1 = val; this.uploadVideo(val, 'videoFile1')},
        videoFile2: function(val) {this.videoFile2 = val; this.uploadVideo(val, 'videoFile2')},
        videoFile3: function(val) {this.videoFile3 = val; this.uploadVideo(val, 'videoFile3')}
    },
    methods:{
        GetProjectEvents(){
            this.processing = true;
            axios.get('/Project/GetProjectEvents', { params: { projectID: this.projectID }})
            .then(response => {
                this.projectEventsDetails = response.data;            
            })
        },
        GetProjectPublicById(){
            axios.get('/Project/GetProjectPublicById', { params: { projectID: this.projectID }})
            .then(response => {                
                this.project = response.data;          
            })
        },
        createEvent(){               
            if(this.eventSelected.projectEventID != null || this.eventSelected.projectEventID != undefined){                
                this.projectID = null,
                this.eventType = null,
                this.description = null,
                this.eventDate = null,
                this.picture1Url = null,
                this.picture2Url = null,
                this.picture3Url = null,
                this.picture4Url = null,
                this.picture5Url = null,
                this.file1Url = null,
                this.file2Url = null,
                this.file3Url = null,
                this.eventVideoUrl1 = null,
                this.eventVideoUrl2 = null,
                this.eventVideoUrl3 = null,
                this.link = null
            }
            this.showEventModal()
        },        
        SaveProjectEvent(){   
            this.processing = true;         
            if(!this.eventType || !this.eventDate || !this.description){
                this.$bvToast.toast("Debes diligenciar Evento, Fecha de evento y Descripción", { title: 'Email', variant: 'danger', solid: true, })
            } else {                    
                    this.eventSelected.projectID = this.projectID  
                    this.eventSelected.eventType = this.eventType,
                    this.eventSelected.description = this.description,
                    this.eventSelected.eventDate = this.eventDate
                    this.eventSelected.link = this.link

                var payload = {
                    eventSelected: this.eventSelected,
                    sendNotificationCheck: this.sendNotificationCheck
                }                
            
                axios.post('/Project/SaveProjectEvent', payload)
                .then(response => {
                    this.processing = false;
                    if(response.data) {     
                        this.$bvToast.toast("Información guardada de forma exitosa", { title: 'Ok', variant: 'success', solid: true, });  
                        this.project = response.data;   
                        this.hideEventModal();       
                    } else {
                        this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
                    }                    
                    window.location.reload()
                })            
            }
        },
        eventTypeSelected(event){
            this.eventType = event;
        },
        openDocument(fileUrl) {
            window.open(fileUrl, '_blank');
        }, 
        editEvent(){
            this.GetProjectEventByID()
        },
        GetProjectEventByID(){
            this.projectEventID = this.eventSelected.projectEventID             
            axios.get('/Project/GetProjectEventByID', { params: { projectEventID: this.projectEventID}})
            .then(response => {
                this.eventSelected = response.data;
                this.projectEventID = this.eventSelected.projectEventID
                this.projectID = this.eventSelected.projectID
                this.eventType = this.eventSelected.eventType
                this.description = this.eventSelected.description
                this.eventDate = this.eventSelected.eventDate
                this.picture1Url = this.eventSelected.picture1Url
                this.picture2Url = this.eventSelected.picture2Url
                this.picture3Url = this.eventSelected.picture3Url
                this.picture4Url = this.eventSelected.picture4Url
                this.picture5Url = this.eventSelected.picture5Url
                this.file1Url = this.eventSelected.file1Url
                this.file2Url = this.eventSelected.file2Url
                this.file3Url = this.eventSelected.file3Url  
                this.eventVideoUrl1 = this.eventSelected.eventVideoUrl1
                this.eventVideoUrl2 = this.eventSelected.eventVideoUrl2
                this.eventVideoUrl3 = this.eventSelected.eventVideoUrl3     
                this.link = this.eventSelected.link              
    
                this.hideEditDeleteModal();
                this.showEventModal();
            })
        },
        onRowSelected(items) {
            this.eventSelected = items[0];
            if(this.eventSelected){
                this.showModalToEditDelete() 
            }                         
        },  
        showEventModal() {     
            this.processing = false,     
            this.$refs['create-new-event'].show()         
        },
        hideEventModal(){
        this.$refs['create-new-event'].hide()                  
        },
        showModalToEditDelete() {           
            this.$refs['edit-delete-event'].show()         
        },
        hideEditDeleteModal(){
            this.$refs['edit-delete-event'].hide() 
        },
        deleteEvent(){
            this.projectEventID = this.eventSelected.projectEventID 
            axios.get('/Project/DeleteEvent', { params: { projectEventID: this.projectEventID}})
            .then(response => {                 
                this.hideEditDeleteModal();
                if(response.data) {     
                    this.$bvToast.toast("Peso ha sido borrado", { title: 'Ok', variant: 'success', solid: true, });  
                    this.project = response.data;          
                } else {
                    this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
                }
                window.location.reload()   
            })
        },

        uploadFile(fileToUpload, fileType) {
            console.log({
                fileToUpload,fileType
            })
            this.processing = true;               
            let formData = new FormData();
            formData.append('file', fileToUpload);
            axios.post('/File/UploadFile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
            this.processing = false;
            if(response.data) {                
                this.setFileUrl(fileType, response.data);
                this.$bvToast.toast("Documento subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });            
                
            } else {
                this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
            }
            })
            .catch(error => {
            this.processing = false;
                if(error.response){
                this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
        },
        setFileUrl(fileType, fileUrl) {            
            switch(fileType) {
                case 'picture1File':
                    this.eventSelected.picture1Url = fileUrl;
                    break;
                case 'picture2File':
                    this.eventSelected.picture2Url = fileUrl;
                    break;
                case 'picture3File':
                    this.eventSelected.picture3Url = fileUrl;
                    break;
                case 'picture4File':
                    this.eventSelected.picture4Url = fileUrl;
                    break;
                case 'picture5File':
                    this.eventSelected.picture5Url = fileUrl;
                    break;
                case 'file1File':
                    this.eventSelected.file1Url = fileUrl;
                    break;
                case 'file2File':
                    this.eventSelected.file2Url = fileUrl;
                    break;
                case 'file3File':
                    this.eventSelected.file3Url = fileUrl;
                    break;
                case 'videoFile1':
                    this.eventSelected.eventVideoUrl1 = fileUrl;
                    break;
                case 'videoFile2':
                    this.eventSelected.eventVideoUrl2 = fileUrl;
                    break;
                case 'videoFile3':
                    this.eventSelected.eventVideoUrl3 = fileUrl;
                    break;                    
            }
        },
        changeSendNotificationCheck(){
            if(this.sendNotificationCheck == true){
                this.sendNotificationCheck = false
            } else{
                this.sendNotificationCheck = true
            }
        },        
        uploadVideo(fileToUpload, fileType) {
            this.processing = true;               
            let formData = new FormData();            
            formData.append('file', fileToUpload);
            axios.post('/File/UploadVideo', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
            this.processing = false;
            if(response.data) {                
                this.setFileUrl(fileType, response.data);
                this.$bvToast.toast("Video subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });            
                
            } else {
                this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
            }
            })
            .catch(error => {
            this.processing = false;
                if(error.response){
                this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
        },
        openVideo(url){            
            this.url = url
            window.open(this.url, '_blank');
        }
    }
}
</script>